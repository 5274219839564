// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importer les fichiers de traduction
import translationEN from "./locales/en.json";
import translationFR from "./locales/fr.json";
import translationRU from "./locales/ru.json";

// Options de détection de langue
const detectionOptions = {
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  caches: ["localStorage", "cookie"],
  cookieMinutes: 10080, // 7 jours
  cookieDomain: "myDomain",
  cookieOptions: { path: "/", sameSite: "strict" },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
      ru: { translation: translationRU },
    },
    fallbackLng: "en",
    detection: detectionOptions,
    debug: true, // Active le mode débogage pour voir les logs dans la console
    interpolation: {
      escapeValue: false,
    },
  });

console.log("i18next détecté la langue:", i18n.language); // Ajoute une log pour voir quelle langue est détectée

export default i18n;
