// src/App.js
import React from "react";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation(); // Utilisation du hook useTranslation

  // Fonction pour changer la langue
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      {/* Sélecteur de langue en haut à droite */}
      <div style={{ position: "absolute", top: "20px", right: "20px" }}>
        <select
          onChange={(e) => changeLanguage(e.target.value)}
          value={i18n.language}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
          <option value="ru">Русский</option>
        </select>
      </div>

      {/* Contenu principal de la landing page */}
      <h1>{t("title")}</h1>
      <p>{t("description")}</p>
      <button>{t("cta")}</button>
    </div>
  );
}

export default App;
